/////////////////////////////////////////////////////////////////////////////////////TYPES
import { GET_MESSAGES, CLEAR_MESSAGES } from '../actions/types/types';
/////////////////////////////////////////////////////////////////////MESSAGE DEFAULT STATE
const alertDefaultState = {
  text: null,
  isError: false,
  status: null,
  id: null,
};
///////////////////////////////////////////////////////////////////////////MESSAGE REDUCER
const alert = (state = alertDefaultState, action) => {
  switch (action.type) {
    case GET_MESSAGES:
      return {
        text: action.payload.text,
        isError: action.payload.isError,
        status: action.payload.status,
        id: action.payload.id,
      };
    case CLEAR_MESSAGES:
      return {
        text: null,
        isError: false,
        status: null,
        id: null,
      };

    default:
      return state;
  }
};

export default alert;
