///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Alert, BookingTable, Spinner } from '@ibiliaze/reactstrap';
import { msToTime, getNow } from '@ibiliaze/time';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getBookings } from '../../actions/bookings';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Wishlist from '../Booking/Wishlist';
import ManagementCanvas from '../Booking/ManagementCanvas';
import ManagementHeader from '../Booking/ManagementHeader';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { env } from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const BookingsPage = ({ bookings, isAuthenticated, settings, getBookings }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [selectedTime, setSelectedTime] = useState(false);
  const [booking, setBooking] = useState(false);
  const [modal, setModal] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [firstDay, setFirstDay] = useState(0);
  const [now, setNow] = useState(getNow(env.REACT_APP_TIMEZONE));

  // Toggle functions
  const toggleCanvas = _ => setCanvas(!canvas);
  const toggleModal = _ => setModal(!modal);

  // Drag functions
  const onDragEnd = position => {
    if (isAuthenticated && !position.unbookable) {
      setBooking(position.booking);
      setSelectedTime(position.time);
      toggleCanvas();
    }
  };

  // onChange functions
  const onDateChange = midnight => setFirstDay(midnight);

  // onClick functions
  const onFieldClick = (time, booking, unbookable) => {
    if (isAuthenticated && !unbookable) {
      setBooking(booking);
      setSelectedTime(!!booking ? booking.bookingTime : time);
      toggleCanvas();
    } else if (!isAuthenticated && !booking && !unbookable) {
      setBooking(false);
      setSelectedTime(time);
      toggleModal();
    }
  };

  const onSearchClick = booking => {
    setBooking(booking);
    toggleCanvas();
  };

  // Lifecycle hooks
  useEffect(() => {
    setLoading(true);
    if (firstDay !== 0) getBookings(`?after=${firstDay}&before=${firstDay + 604800000}`);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDay]);

  useEffect(() => {
    const interval = setInterval(() => setNow(Date.now()), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // JSX
  return (
    <div className='below-header' style={{ minHeight: '100vh' }}>
      <h1 className='t-align-c no-m'>Bookings</h1>

      <section className='p-t-m page'>
        <Alert color='primary'>
          Please book your appointment after{' '}
          {msToTime(now + settings?.earliestBooking, 0, env.REACT_APP_LOCALE_DATE, env.REACT_APP_TIMEZONE)}
        </Alert>
        {isAuthenticated && (
          <section className='p-t-m'>
            <ManagementHeader getBookings={getBookings} onSearchClickHandler={onSearchClick} firstDay={firstDay} />
          </section>
        )}
        {loading ? (
          <Spinner
            color='primary'
            style={{
              display: 'grid',
              margin: 'auto',
              marginTop: '24px',
              height: '5rem',
              width: '5rem',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        ) : (
          <BookingTable
            bookings={bookings}
            workingHours={{ days: settings.workingHours, interval: settings.interval }}
            onFieldClickHandler={onFieldClick}
            onDragEndHandler={onDragEnd}
            onDateChangeHandler={onDateChange}
            localeDate={env.REACT_APP_LOCALE_DATE}
            timeZone={env.REACT_APP_TIMEZONE}
            bookingColourRgb='110, 37, 37'
          />
        )}
        <Wishlist modal={modal} toggle={toggleModal} selectedTime={selectedTime} />
        <ManagementCanvas canvas={canvas} toggle={toggleCanvas} booking={booking} selectedTime={selectedTime} />
      </section>
    </div>
  );
};

const mapStateToProps = state => ({
  bookings: state.bookings,
  isAuthenticated: state.user.isAuthenticated,
  settings: state.settings,
});

export default connect(mapStateToProps, { getBookings })(BookingsPage);
