///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CustomAlert } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const exludeStatusCodes = [];

const Alert = ({ alert }) => {
  // State
  const [visible, setVisible] = useState(true);

  // Lifecycle hooks
  useEffect(() => {
    !!alert.text && !exludeStatusCodes.includes(alert.status) ? setVisible(true) : setVisible(false);
  }, [alert]);

  // JSX
  return (
    visible && (
      <CustomAlert open={visible} setOpen={setVisible} type={alert.isError ? 'danger' : 'success'} text={alert.text} />
    )
  );
};

const mapStateToProps = state => ({ alert: state.alert });

export default connect(mapStateToProps)(Alert);
