export const env = process.env;

const constants = {
  cust: 'Stock Tuner',
  custSm: 'stock tuner',
  custJs: 'stockTuner',
  slogan: 'We Remap Cars and Further',
  phone1: '+447570695235',
  email1: 'stocktunerldn@gmail.com',
  hqAddr: '4 Dereham Road, IG11 9HA, Barking, Essex',
  chatPerson: 'Vagif',
  chatMessage: `Hello there! \n \nHow can we help?`,
  chatStatus: `Typically replies within a few minutes`,
  mainPic: '/img/stm.jpg',
  mainPicButton: 'Book an Appointment',
  instagram: 'stocktuner',
  // facebook: 'stocktuner',
  // twitter: 'stocktuner',
  // banner: {
  //   title: 'Warning',
  //   text: 'Warning text',
  //   bottomText: 'More warning',
  // },
  secondaryPic: '/img/bikini.jpg',
  secondaryPicTitle: 'BEAUTY',
  secondaryPicText:
    "Aesthetics and beauty play a crucial role in enhancing one's confidence and self-image. At our beauty clinic, we believe that everyone deserves to feel their best, and that's why we strive to provide the highest quality aesthetic treatments.",
  secondaryPicButton: 'Learn More',
  aboutUs: [
    {
      header: 'ECU Remappping',
      body: "Modifying the electronic control unit of a vehicle's engine to enhance performance, fuel efficiency, or other parameters",
      imageSrc: '/img/remap.jpg',
    },
    {
      header: 'Diagnostics Reading',
      body: " Using specialized tools to retrieve and interpret electronic data from a vehicle's onboard systems, helping to identify and troubleshoot potential issues.",
      imageSrc: '/img/diag.jpg',
    },
    {
      header: 'Mobile Available',
      body: 'We can come directly to your vehicle.',
      imageSrc: '/img/mobile.png',
    },
  ],
  services: {
    currency: '£',
    serviceGroups: [
      {
        serviceGroupName: 'Tuning',
        serviceItems: [
          {
            serviceName: 'ECU Remap',
            id: 'ECU Remap',
            imageSrc: '/img/remap.jpg',
            description:
              'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
            price: 200,
            duration: '03:00',
            courses: 1,
          },
        ],
      },
      {
        serviceGroupName: 'Servicing',
        serviceItems: [
          {
            serviceName: 'Diagnistics Reading',
            id: 'Diagnistics Reading',
            imageSrc: '/img/diag.jpg',
            description: 'Includes the chest area and stomach',
            price: 20,
            duration: '02:00',
            courses: 1,
          },
        ],
      },
    ],
  },
  reviews: [],
};

export default constants;
