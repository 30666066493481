///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import { GET_SETTINGS_FAILURE, GET_SETTINGS_SUCCESS, PUT_SETTINGS_FAILURE, PUT_SETTINGS_SUCCESS } from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/settings
export const getSettingsSuccess = settings => ({ type: GET_SETTINGS_SUCCESS, settings });
export const getSettingsFailure = errorMessage => ({ type: GET_SETTINGS_FAILURE, errorMessage });
export const getSettings = endpoint => async dispatch => {
  try {
    const res = await api.get(`${resolveHost()}/api/settings`);

    dispatch(setAlert(res.data.message, false, res.status, GET_SETTINGS_SUCCESS));
    dispatch(getSettingsSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, GET_SETTINGS_FAILURE));
    dispatch(getSettingsFailure(e.message));
  }
};

// PUT /api/settings
export const putSettingsSuccess = settings => ({ type: PUT_SETTINGS_SUCCESS, settings });
export const putSettingsFailure = errorMessage => ({ type: PUT_SETTINGS_FAILURE, errorMessage });
export const putSettings = data => async dispatch => {
  try {
    const res = await api.put(`${resolveHost()}/api/settings`, data);

    dispatch(setAlert(res.data.message, false, res.status, PUT_SETTINGS_SUCCESS));
    dispatch(putSettingsSuccess(res.data.settings));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, PUT_SETTINGS_FAILURE));
    dispatch(putSettingsFailure(e.message));
  }
};
