///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import {
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_FAILURE,
  POST_REVIEW_SUCCESS,
  POST_REVIEW_FAILURE,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAILURE,
} from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/review
export const getReviewsSuccess = reviews => ({ type: GET_REVIEWS_SUCCESS, reviews });
export const getReviewsFailure = errorMessage => ({ type: GET_REVIEWS_FAILURE, errorMessage });
export const getReviews = endpoint => async dispatch => {
  try {
    const res = await api.get(
      endpoint === undefined ? `${resolveHost()}/api/review` : `${resolveHost()}/api/review${endpoint}`
    );

    dispatch(setAlert(res.data.message, false, res.status, GET_REVIEWS_SUCCESS));
    dispatch(getReviewsSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, GET_REVIEWS_FAILURE));
    dispatch(getReviewsFailure(e.message));
  }
};

// POST /api/review
export const postReviewSuccess = review => ({ type: POST_REVIEW_SUCCESS, review });
export const postReviewFailure = errorMessage => ({ type: POST_REVIEW_FAILURE, errorMessage });
export const postReview = data => async dispatch => {
  try {
    const res = await api.post(`${resolveHost()}/api/review`, data);

    dispatch(setAlert(res.data.message, false, res.status, POST_REVIEW_SUCCESS));
    dispatch(postReviewSuccess(res.data.review));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, POST_REVIEW_FAILURE));
    dispatch(postReviewFailure(e.message));
  }
};

// DELETE /api/review
export const deleteReviewSuccess = review => ({ type: DELETE_REVIEW_SUCCESS, review });
export const deleteReviewFailure = errorMessage => ({ type: DELETE_REVIEW_FAILURE, errorMessage });
export const deleteReview = reviewId => async dispatch => {
  try {
    const res = await api.delete(`${resolveHost()}/api/review/${reviewId}`);

    dispatch(setAlert(res.data.message, false, res.status, DELETE_REVIEW_SUCCESS));
    dispatch(deleteReviewSuccess(res.data.review));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, DELETE_REVIEW_FAILURE));
    dispatch(deleteReviewFailure(e.message));
  }
};
