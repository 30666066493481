///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  CustomButton,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Form,
  FormGroup,
  FormText,
  CustomInput,
  CloseButton,
  Label,
  Spinner,
} from '@ibiliaze/reactstrap';
import { msToHm, msToDtl, roundMs } from '@ibiliaze/time';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { postBooking } from '../../actions/bookings';
import { deleteItem, resetWishlist } from '../../actions/wishlist';
import { confirmEmail } from '../../actions/email';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Wishlist = ({ toggle, modal, selectedTime = '', wishlist, settings, postBooking, confirmEmail, deleteItem }) => {
  // State
  const [inputs, setInputs] = useState({
    clientName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    postcode: '',
    code: '',
    notes: '',
  });
  const [loading, setLoading] = useState(false);

  // Navigate
  const navigate = useNavigate();

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value });

  // onClick functions
  const onRemoveClick = serviceName => deleteItem(serviceName);
  const onSendConfirmationClick = _ => confirmEmail({ email: inputs.email });

  const onBookClick = e => {
    e.preventDefault();

    setLoading(true);
    if (selectedTime === '') {
      window.scrollTo(0, 0);
      navigate('/bookings');
    } else {
      const payload = {
        ...inputs,
        email: inputs.email?.toLowerCase(),
        services: wishlist.items.map(s => s.serviceName),
        paid: false,
        price: wishlist.total,
        confirmed: false,
        completed: false,
        bookingTime: selectedTime,
        duration: roundMs(wishlist.duration, settings.roundBooking, settings.interval),
      };
      postBooking(payload);
    }

    setLoading(false);
    toggle();
  };

  // JSX
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <Form onSubmit={onBookClick}>
        <ModalHeader toggle={toggle}>Wishlist</ModalHeader>
        <ModalBody>
          <div>
            <Table borderless className='wishlist-table'>
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {wishlist.items.map((service, i) => {
                  return (
                    <tr key={i}>
                      <td>{service.serviceName}</td>
                      <td>
                        {constants.services.currency}
                        {service.price}
                      </td>
                      <td>
                        <CloseButton onClick={_ => onRemoveClick(service.id)} />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <th>Total</th>
                  <th>
                    {constants.services.currency}
                    {wishlist.total}
                  </th>
                </tr>
                <tr>
                  <th>Duration</th>
                  <th>{msToHm(wishlist.duration)}</th>
                </tr>
              </tbody>
            </Table>
          </div>
          {selectedTime !== '' && (
            <>
              <FormGroup>
                <CustomInput
                  required
                  name='clientName'
                  placeholder='Name'
                  value={inputs.clientName}
                  onChange={onInputsChange}
                />
              </FormGroup>
              <FormGroup>
                <CustomInput
                  required
                  name='address'
                  placeholder='Address Line'
                  value={inputs.address}
                  onChange={onInputsChange}
                />
              </FormGroup>
              <FormGroup>
                <CustomInput required name='city' placeholder='City' value={inputs.city} onChange={onInputsChange} />
              </FormGroup>
              <FormGroup>
                <CustomInput
                  required
                  name='postcode'
                  placeholder='Postcode'
                  value={inputs.postcode}
                  onChange={onInputsChange}
                />
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <CustomInput
                    required
                    name='email'
                    placeholder='Email'
                    value={inputs.email}
                    onChange={onInputsChange}
                  />
                  <CustomButton onClick={onSendConfirmationClick} color='success'>
                    Send Code
                  </CustomButton>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <CustomInput
                  required
                  name='code'
                  placeholder='One Time Passcode'
                  value={inputs.node}
                  onChange={onInputsChange}
                />
                <FormText>Your One Time Passcode (OTP) can only be used once</FormText>
              </FormGroup>
              <FormGroup>
                <CustomInput
                  required
                  name='phone'
                  type='tel'
                  placeholder='Mobile'
                  value={inputs.phone}
                  onChange={onInputsChange}
                />
              </FormGroup>
              <FormGroup>
                <CustomInput disabled required type='datetime-local' value={msToDtl(selectedTime)} onChange={_ => {}} />
              </FormGroup>
              <FormGroup>
                <Label>Notes for {constants.cust}</Label>
                <CustomInput
                  placeholder='Notes'
                  name='notes'
                  type='textarea'
                  rows='2'
                  value={inputs.notes}
                  onChange={onInputsChange}
                />
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <CustomButton
            type='submit'
            disabled={loading ? true : wishlist.items.length === 0 ? true : false}
            color='primary'
          >
            {loading ? (
              <Spinner size='sm' />
            ) : selectedTime === '' ? (
              'Book your time'
            ) : constants.bookingOption === 'PAYMENT_FIRST' ? (
              'Proceed to Checkout'
            ) : (
              'Make a Booking'
            )}
          </CustomButton>
          <CustomButton onClick={toggle}>Close</CustomButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({ wishlist: state.wishlist, settings: state.settings });

export default connect(mapStateToProps, { postBooking, confirmEmail, deleteItem, resetWishlist })(Wishlist);
