///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  CustomInput,
  Button,
  FormGroup,
  Label,
  CustomCard,
} from '@ibiliaze/reactstrap';
import errorParser from '@ibiliaze/http-error-parser';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from '../../actions/alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
import { api } from '../../utils/httpRequest';
import scroll from '../../utils/scroll';
import { resolveHost } from '../../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

const About = ({ setAlert }) => {
  // State
  const [inputs, setInputs] = useState({ postcode: '', reg: '' });
  const [vehicle, setVehicle] = useState(null);
  const [loading, setLoading] = useState(false);

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value?.toUpperCase() });

  // onClick functions
  const onSearchClick = _ => {
    const req = async () => {
      setLoading(true);
      try {
        const res = await api.get(`${resolveHost()}/api/vehicle/${inputs.reg}`);
        if (typeof res.data === 'object' && !Array.isArray(res.data)) {
          setVehicle(res.data?.vehicle);
        } else {
          setVehicle(null);
          throw new Error('Vehicle not found');
        }
        setLoading(false);
      } catch (e) {
        setAlert(errorParser(e), true, e.response ? e.response.status : 500, null);
        setLoading(false);
      }
    };
    req();
  };

  // JSX
  return (
    <section id='about-section' className='page p-t-xl p-b-xl'>
      <h1 className='t-align-c'>
        <span>
          <img alt='img' src='/logo512.png' style={{ height: '3.2rem' }} />
        </span>
        <span>Upgrade Your Car</span>
      </h1>

      <div className='spinner-block-container'>
        {loading ? (
          <div className='spinner-block'>
            <div>
              <h3 className='t-align-c loading-text'>Searching...</h3>
              <img alt='img' src='/img/tire.gif' class='spinner-img' />
            </div>
          </div>
        ) : (
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Postcode</Label>
                <CustomInput
                  name='postcode'
                  value={inputs.postcode}
                  onChange={onInputsChange}
                  size='lg'
                  placeholder='Postcode'
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Vehicle Registration</Label>
                <InputGroup className='reg-input-group'>
                  <InputGroupText className='reg-input-group-text'>GB</InputGroupText>
                  <Input
                    name='reg'
                    value={inputs.reg}
                    onChange={onInputsChange}
                    className='reg-input'
                    size='lg'
                    placeholder='REG'
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Find your car</Label>
                <br />
                <Button onClick={onSearchClick} color='primary' size='lg' style={{ width: '100%' }}>
                  Search
                </Button>
              </FormGroup>
            </Col>

            {!!vehicle && (
              <div className='t-align-c'>
                <br />
                <h2>{vehicle.make}</h2>
                <h3>
                  {vehicle.yearOfManufacture} {vehicle.engineCapacity / 1000 || 0}l {vehicle.fuelType}
                </h3>
              </div>
            )}
          </Row>
        )}
      </div>
      <hr />
      <Row>
        {constants.aboutUs.map((content, i) => (
          <Col key={i} md={4}>
            <CustomCard
              imgSrc={content.imageSrc}
              title={content.header}
              text={content.body}
              hoverTitle='Book'
              onCardClickHandler={_ => scroll(document.getElementById('services-section')?.offsetTop)}
              multiclick={false}
            />
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default connect(null, { setAlert })(About);
