///////////////////////////////////////////////////////////////////////////////////MODULES
import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const ChatWidget = () => (
  <FloatingWhatsApp
    phoneNumber={constants.phone1}
    accountName={constants.chatPerson}
    statusMessage={constants.chatStatus}
    chatboxStyle={{ boxShadow: '0 0 20px rgba(9, 11, 14, 0.5)' }}
    chatMessage={constants.chatMessage}
    allowEsc
    allowClickAway
    avatar='/img/vagif.JPG'
    style={{ zIndex: 10 }}
  />
);

export default ChatWidget;
