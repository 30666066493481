///////////////////////////////////////////////////////////////////////////////////MODULES
import { Col, Row, MainImage, CustomButton } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
import scroll from '../../utils/scroll';
//////////////////////////////////////////////////////////////////////////////////////////

const Copy = () => (
  <>
    <section className='page p-t-xl p-b-xl'>
      <h1 className='t-align-c'>
        Book with <span className='text-gradient'>Mobile</span> and <span className='text-gradient'>Desktop</span>
      </h1>
      <Row>
        <Col md={6}>
          <h3>
            <span className='text-gradient'>Easier</span> for You
          </h3>
          <p>
            With <b>{constants.cust}</b>, customers have the option to easily book appointments either through their
            mobile device or desktop computer. On the go? Simply visit our mobile-friendly website and schedule your
            appointment in just a few taps. Prefer to use your desktop? No problem, visit our website and follow the
            simple steps to secure your appointment time. Either way, booking with <b>{constants.cust}</b> has never
            been easier and more convenient.
          </p>
          <CustomButton onClick={_ => scroll(document.getElementById('services-section')?.offsetTop)} color='primary'>
            Choose your Service
          </CustomButton>
        </Col>
        <Col md={2}>
          <img alt='phone' src='/img/phone.png' style={{ width: '100%' }} />
        </Col>
        <Col md={4}>
          <img alt='phone' src='/img/surface.png' style={{ width: '100%' }} />
        </Col>
      </Row>
    </section>
    <div className='p-t-xl p-b-xl'>
      <MainImage
        imgPath='/img/honda.jpg'
        imgHeight='800px'
        imgMobileHeight='800px'
        imgBrightness='0.4'
        slogan={`Safe ECU tuning offers a responsible way to unlock additional performance potential in your vehicle, providing an exhilarating driving experience without compromising safety. By carefully optimizing the engine's parameters, ECU tuning can enhance power and torque delivery while staying well within the manufacturer's safety limits. This results in a car that feels more responsive and enjoyable to drive, ensuring that the added performance is harnessed safely and reliably. With the right expertise, ECU tuning can transform your driving experience into a thrilling adventure while maintaining the integrity of your vehicle's components and ensuring peace of mind on the road.`}
      />
    </div>
  </>
);

export default Copy;
