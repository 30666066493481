///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  CustomButton,
  ButtonGroup,
  InputGroup,
  CustomInputGroupText,
  FormGroup,
  Form,
  Label,
  CustomInput,
  CustomMultiSelect,
  Spinner,
} from '@ibiliaze/reactstrap';
import { dtlToMs, roundMs, hmToMs, msToHm, msToTime, msToDtl } from '@ibiliaze/time';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { putBooking, deleteBooking, postBookingAdmin } from '../../actions/bookings';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants, { env } from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const ManagementCanvas = ({
  toggle,
  canvas,
  booking,
  selectedTime,
  putBooking,
  postBookingAdmin,
  deleteBooking,
  settings,
}) => {
  // State
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [inputs, setInputs] = useState({
    bookingId: '',
    clientName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    postcode: '',
    paid: false,
    price: '',
    bookingTime: '',
    duration: settings.interval,
    notes: '',
  });

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.checked || e.target.value });

  // onClick functions
  const onDeleteClick = _ => {
    if (!!booking) {
      setLoading(true);
      deleteBooking(booking._id);
      setLoading(false);
    }
  };

  const onSaveClick = e => {
    e.preventDefault();

    setLoading(true);
    const payload = {
      ...booking,
      ...inputs,
      email: inputs.email?.toLowerCase(),
      services: selected.map(s => s.value),
      duration: roundMs(hmToMs(inputs.duration), settings.roundBooking, settings.interval),
      bookingTime: roundMs(dtlToMs(inputs.bookingTime), 'nearest', settings.interval),
    };
    const req = !!booking ? putBooking : postBookingAdmin;
    req({ ...payload });
    setLoading(false);
    toggle();
  };

  // Lifecycle hooks
  useEffect(() => {
    if (!!booking) {
      setInputs(c => ({ ...c, bookingTime: msToDtl(selectedTime) }));
      setSelected([...booking.services.map(s => ({ label: s, value: s }))]);
    } else {
      setInputs(c => ({
        ...c,
        bookingId: '',
        clientName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        postcode: '',
        paid: false,
        price: '',
        bookingTime: msToDtl(selectedTime),
      }));
      setSelected([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTime]);

  useEffect(() => {
    setInputs(c => ({
      ...c,
      bookingId: booking?._id || '',
      clientName: booking?.clientName || '',
      email: booking?.email || '',
      phone: booking?.phone || '',
      address: booking?.address || '',
      city: booking?.city || '',
      postcode: booking?.postcode || '',
      paid: booking?.paid || false,
      price: booking?.price || '',
      bookingTime: msToDtl(selectedTime),
      duration: booking?.duration ? msToHm(booking?.duration) : msToHm(settings.interval),
      notes: booking?.notes || '',
    }));
    setSelected(
      !!booking?.services && booking?.services.length !== 0
        ? [...booking.services.map(s => ({ label: s, value: s }))]
        : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  // JSX
  return (
    <Offcanvas fade scrollable isOpen={canvas} toggle={toggle}>
      <OffcanvasHeader toggle={toggle}>Management</OffcanvasHeader>
      <OffcanvasBody>
        <p>{msToTime(selectedTime, 0, env.REACT_APP_LOCALE_DATE, env.REACT_APP_TIMEZONE)}</p>
        <Form onSubmit={onSaveClick}>
          <FormGroup>
            <InputGroup>
              <CustomInputGroupText>{!!booking ? 'EDIT' : 'NEW'}</CustomInputGroupText>
              <CustomInput
                required
                placeholder='Client Name'
                name='clientName'
                value={inputs.clientName}
                onChange={onInputsChange}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <CustomInput
                required
                placeholder='Email'
                type='email'
                name='email'
                value={inputs.email}
                onChange={onInputsChange}
              />
              <CustomInput
                required
                placeholder='Phone'
                type='tel'
                name='phone'
                value={inputs.phone}
                onChange={onInputsChange}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <CustomInputGroupText>{constants.services.currency}</CustomInputGroupText>
              <CustomInput
                valid={inputs.paid ? true : false}
                required
                type='number'
                placeholder='Price'
                name='price'
                value={inputs.price}
                onChange={onInputsChange}
              />
              <CustomButton
                color={inputs.paid ? 'secondary' : 'primary'}
                onClick={_ => setInputs({ ...inputs, paid: !inputs.paid })}
              >
                {inputs.paid ? 'Unpaid' : 'Paid'}
              </CustomButton>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label>Services</Label>
            <CustomMultiSelect
              required
              name='services'
              value={selected}
              onChange={setSelected}
              labelledBy='Select'
              options={[].concat(
                ...constants.services.serviceGroups.map(sg =>
                  sg.serviceItems.map(s => ({ label: s.serviceName, value: s.serviceName }))
                )
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label>Booking Time</Label>
            <CustomInput
              required
              type='datetime-local'
              name='bookingTime'
              value={inputs.bookingTime}
              onChange={onInputsChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Duration</Label>
            <CustomInput required type='time' name='duration' value={inputs.duration} onChange={onInputsChange} />
          </FormGroup>
          <FormGroup>
            <Label>Address Line</Label>
            <CustomInput
              required
              placeholder='Address Line'
              name='address'
              value={inputs.address}
              onChange={onInputsChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>City</Label>
            <CustomInput required placeholder='City' name='city' value={inputs.city} onChange={onInputsChange} />
          </FormGroup>
          <FormGroup>
            <Label>Postcode</Label>
            <CustomInput
              required
              placeholder='Postcode'
              name='postcode'
              value={inputs.postcode}
              onChange={onInputsChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Notes</Label>
            <CustomInput
              placeholder='Notes'
              name='notes'
              type='textarea'
              rows='2'
              value={inputs.notes}
              onChange={onInputsChange}
            />
          </FormGroup>
          {!!booking && (
            <div>
              {booking._id}
              <br />
              <br />
            </div>
          )}
          <ButtonGroup style={{ float: 'left' }}>
            <CustomButton disabled={loading ? true : false} outline onClick={onDeleteClick} color='danger'>
              {loading ? <Spinner size='sm' /> : 'Remove'}
            </CustomButton>
            <CustomButton disabled={loading ? true : false} outline type='submit' color='primary'>
              {loading ? <Spinner size='sm' /> : 'Save'}
            </CustomButton>
          </ButtonGroup>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
};

const mapStateToProps = state => ({ settings: state.settings });

export default connect(mapStateToProps, { putBooking, postBookingAdmin, deleteBooking })(ManagementCanvas);
