///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  PUT_USER_SUCCESS,
  PUT_USER_FAILURE,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
} from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

export const getUserSuccess = payload => ({ type: GET_USER_SUCCESS, payload });
export const getUserFailure = errorMessage => ({ type: GET_USER_FAILURE, errorMessage });
export const getUser = endpoint => async dispatch => {
  try {
    const res = await api.get(
      endpoint === undefined ? `${resolveHost()}/api/user` : `${resolveHost()}/api/user${endpoint}`
    );

    dispatch(setAlert(res.data.message, false, res.status, GET_USER_SUCCESS));
    dispatch(getUserSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, GET_USER_FAILURE));
    dispatch(getUserFailure(e.message));
  }
};

// Register
export const registerSuccess = payload => ({ type: REGISTER_SUCCESS, payload });
export const registerFailure = errorMessage => ({ type: REGISTER_FAILURE, errorMessage });
export const register = data => async dispatch => {
  try {
    const res = await api.post(`${resolveHost()}/api/user`, data);

    dispatch(setAlert(res.data.message, false, res.status, REGISTER_SUCCESS));
    dispatch(registerSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, REGISTER_FAILURE));
    dispatch(registerFailure(e.message));
  }
};

// Login
export const loginSuccess = payload => ({ type: LOGIN_SUCCESS, payload });
export const loginFailure = errorMessage => ({ type: LOGIN_FAILURE, errorMessage });
export const login = data => async dispatch => {
  try {
    const res = await api.post(`${resolveHost()}/api/user/token`, data);

    dispatch(setAlert(res.data.message, false, res.status, LOGIN_SUCCESS));
    dispatch(loginSuccess(res.data));
    dispatch(getUser());
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, LOGIN_FAILURE));
    dispatch(loginFailure(e.message));
  }
};

// Logout
export const logoutSuccess = payload => ({ type: LOGOUT_SUCCESS, payload });
export const logoutFailure = errorMessage => ({ type: LOGOUT_FAILURE, errorMessage });
export const logout = () => async dispatch => {
  try {
    const res = await api.post(`${resolveHost()}/api/user/logout`);

    dispatch(setAlert(res.data.message, false, res.status, LOGOUT_SUCCESS));
    dispatch(logoutSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, LOGOUT_FAILURE));
    dispatch(logoutFailure(e.message));
  }
};

// PUT /api/user
export const putUserSuccess = payload => ({ type: PUT_USER_SUCCESS, payload });
export const putUserFailure = errorMessage => ({ type: PUT_USER_FAILURE, errorMessage });
export const putUser = data => async dispatch => {
  try {
    const res = await api.put(`${resolveHost()}/api/user`, data);

    dispatch(setAlert(res.data.message, false, res.status, PUT_USER_SUCCESS));
    dispatch(putUserSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, PUT_USER_FAILURE));
    dispatch(putUserFailure(e.message));
  }
};

// PUT /api/user/password
export const putUserPassword = data => async dispatch => {
  try {
    const res = await api.put(`${resolveHost()}/api/user/password`, data);

    dispatch(setAlert(res.data.message, false, res.status, PUT_USER_SUCCESS));
    dispatch(putUserSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, PUT_USER_FAILURE));
    dispatch(putUserFailure(e.message));
  }
};

// DELETE /api/user
export const deleteUserSuccess = payload => ({ type: DELETE_USER_SUCCESS, payload });
export const deleteUserFailure = errorMessage => ({ type: DELETE_USER_FAILURE, errorMessage });
export const deleteUser = () => async dispatch => {
  try {
    const res = await api.delete(`${resolveHost()}/api/user`);

    dispatch(setAlert(res.data.message, false, res.status, DELETE_USER_SUCCESS));
    dispatch(deleteUserSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, DELETE_USER_FAILURE));
    dispatch(deleteUserFailure(e.message));
  }
};
